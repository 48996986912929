import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { ForwardedRef, HTMLAttributes, PropsWithChildren, forwardRef } from 'react'

type Justify = 'start' | 'center' | 'end' | 'between' | 'around' | 'evenly'
type Direction = 'row' | 'column'

export type RowProps = HTMLAttributes<HTMLDivElement> & {
    wrap?: boolean
    justify?: Justify
    direction?: Direction
    autoWidth?: boolean
}

const Row = (
    {
        children,
        className,
        wrap = true,
        justify = 'start',
        direction = 'row',
        autoWidth = false,
        ...props
    }: PropsWithChildren<RowProps>,
    ref: ForwardedRef<HTMLDivElement>
) => {
    const getJustifyContent = () => {
        switch (justify) {
            case 'center':
                return 'justify-center'
            case 'end':
                return 'justify-end'
            case 'between':
                return 'justify-between'
            case 'around':
                return 'justify-around'
            case 'evenly':
                return 'justify-evenly'
            default:
                return 'justify-start'
        }
    }

    const getDirection = () => {
        switch (direction) {
            case 'column':
                return 'flex-col'
            default:
                return 'flex-row'
        }
    }

    const classes = buildClassesWithDefault(
        {
            flex: true,
            'flex-wrap': wrap,
            'w-full': !autoWidth,
            [getJustifyContent()]: true,
            [getDirection()]: true
        },
        className
    )

    return (
        <div className={classes} {...props} ref={ref}>
            {children}
        </div>
    )
}

export default forwardRef(Row)
