import { IconProps } from './Icon.types'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React from 'react'

const UsersIcon = ({ fill, className }: IconProps) => {
    return (
        <svg
            className={buildClassesWithDefault('mr-3 h-[24px] w-[20px]', className)}
            width='24'
            height='28'
            viewBox='0 0 24 28'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path d='M9 11a4 4 0 1 0-4-4 4 4 0 0 0 4 4zm0-6a2 2 0 1 1-2 2 2 2 0 0 1 2-2z' fill={fill} />
            <path d='M17 13a3 3 0 1 0-3-3 3 3 0 0 0 3 3zm0-4a1 1 0 1 1-1 1 1 1 0 0 1 1-1z' fill={fill} />
            <path
                d='M17 14a5 5 0 0 0-3.06 1.05A7 7 0 0 0 2 20a1 1 0 0 0 2 0 5 5 0 0 1 10 0 1 1 0 0 0 2 0 6.9 6.9 0 0 0-.86-3.35A3 3 0 0 1 20 19a1 1 0 0 0 2 0 5 5 0 0 0-5-5z'
                fill={fill}
            />
        </svg>
    )
}

export default UsersIcon
