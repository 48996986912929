import { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { ElementType, ForwardedRef, ReactNode, forwardRef } from 'react'

const defaultElementType = 'button'

type BaseDropdownItemProps = {
    icon?: ReactNode
    bordered?: boolean
    active?: boolean
    disableHoverEffect?: boolean
}

export type DropdownItemProps<El extends ElementType = typeof defaultElementType> = PolymorphicPropsWithoutRef<
    BaseDropdownItemProps,
    El
>

const DropdownItem = <El extends ElementType = typeof defaultElementType>(
    { as, children, className, icon, bordered, active, disableHoverEffect, ...props }: DropdownItemProps<El>,
    ref: ForwardedRef<El>
) => {
    const Element: ElementType = as || defaultElementType

    return (
        <Element
            {...props}
            ref={ref}
            className={buildClassesWithDefault(
                {
                    'dropdown-menu-item': true,
                    'disable-hover-effect': disableHoverEffect,
                    bordered,
                    active
                },
                className
            )}
        >
            {icon && <span className='icon'>{icon}</span>}
            {children}
        </Element>
    )
}
export default forwardRef(DropdownItem)
