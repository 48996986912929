import { ReactScrollLinkProps } from 'react-scroll/modules/components/Link'
import { Link as ScrollLink } from 'react-scroll'
import { SidebarItem } from './Sidebar'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import { useRouter } from 'next/router'
import React, { MouseEvent, useEffect, useState } from 'react'

export type SidebarListItemProps = {
    item: SidebarItem
}

const showItemIcon = true

const SidebarListItem = ({
    item: { href, name, icon: Icon, navigation, onClick, active: isItemActive = false, scrollableLink }
}: SidebarListItemProps) => {
    const { pathname, push: routerPush, reload: routerReload } = useRouter()
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (
            navigation &&
            navigation.some(navItem => pathname === navItem.href || pathname.startsWith(`${navItem.href}/`))
        ) {
            setIsOpen(true)
        }
    }, [pathname, navigation])

    const isActiveLink = (path: string) => {
        if (!path) {
            return false
        }
        return pathname === path || pathname.startsWith(`${path}/`) || isItemActive
    }

    const redirectTo = (link: string) => {
        if (link === pathname) {
            routerReload()
            return
        }
        routerPush(link)
    }

    const onItemClick = (event: MouseEvent<HTMLAnchorElement>) => {
        if (navigation) {
            setIsOpen(!isOpen)
        } else if (onClick) {
            onClick(event)
        }
    }

    const renderItem = () => {
        const showIcon = Icon && showItemIcon

        if (href) {
            const classes = {
                active: isActiveLink(href),
                inactive: !isActiveLink(href),
                item: true
            }
            return (
                <a
                    href={href}
                    onClick={event => {
                        event.preventDefault()
                        redirectTo(href)
                    }}
                    className={buildClassesWithDefault(classes, 'group mb-2.5')}
                >
                    {showIcon && (
                        <Icon className='icon' fill={isActiveLink(href) ? 'white' : 'black'} width={20} height={20} />
                    )}
                    {name}
                </a>
            )
        }
        if (scrollableLink) {
            return (
                <ScrollLink
                    className='group item inactive hover:cursor-pointer mb-3'
                    {...(scrollableLink as ReactScrollLinkProps)}
                    activeClass='active'
                >
                    {showIcon && <Icon className='icon' fill='black' />}
                    {name}
                </ScrollLink>
            )
        }
        return (
            <a onClick={onItemClick} className='item inactive group mb-3 cursor-pointer'>
                {showIcon && <Icon className='icon' fill={isActiveLink(href) ? 'black' : 'white'} />}
                {name}
            </a>
        )
    }

    return (
        <div className='group'>
            {renderItem()}
            {isOpen && navigation?.length && (
                <div className='ml-6'>
                    {navigation.map((item, index) => {
                        return <SidebarListItem key={index} item={item} />
                    })}
                </div>
            )}
        </div>
    )
}

export default SidebarListItem
