import { Trans } from 'next-i18next'

export type LabelProps = {
    text?: string
    i18nKey?: string
    isRequired?: boolean
    htmlFor?: string
    subtitle?: string
    srOnly?: boolean
    placeholder?: string
}

export const Label = ({
    text,
    i18nKey,
    isRequired = false,
    htmlFor,
    subtitle,
    srOnly = false,
    placeholder
}: LabelProps) => {
    return (
        <label htmlFor={htmlFor} className={`${srOnly ? 'sr-only' : ''}`}>
            <div>
                {i18nKey ? <Trans i18nKey={i18nKey} /> : text || placeholder}
                {subtitle && <small>{subtitle}</small>}
                {isRequired && <span className='text-primary'> *</span>}
            </div>
        </label>
    )
}
