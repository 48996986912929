import { ButtonProps } from '../button/Button'
import { RxDragHandleDots2 } from 'react-icons/rx'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { ButtonHTMLAttributes, ForwardedRef, HTMLAttributes, PropsWithChildren, forwardRef } from 'react'
import Tooltip from '../tooltip/Tooltip'

export type CardProps = HTMLAttributes<HTMLDivElement> & {
    shadow?: boolean
    border?: boolean
    disabled?: boolean
    hideOverflow?: boolean
    rounded?: boolean
    airy?: boolean | 'xs' | 'sm' | 'md'
    background?: boolean
    isSubcard?: boolean
    grabbingCard?: boolean
    draggableHandleButtonProps?: ButtonHTMLAttributes<HTMLButtonElement>
    isDragging?: boolean
}

const Card = (
    {
        children,
        shadow = true,
        border,
        className,
        disabled,
        hideOverflow = true,
        rounded = true,
        airy,
        background,
        isSubcard = false,
        grabbingCard,
        draggableHandleButtonProps,
        isDragging,
        ...props
    }: PropsWithChildren<CardProps>,
    ref: ForwardedRef<HTMLDivElement>
) => {
    const airyStyle = typeof airy === 'string' ? `airy-${airy}` : 'airy-md'

    const classes = buildClassesWithDefault(
        {
            card: true,
            rounded,
            'shadow-custom-shadow': shadow,
            'card-bordered': border,
            disabled,
            'overflow-x-hidden': hideOverflow,
            'bg-gray-100': background,
            [airyStyle]: !!airy
        },
        className
    )

    const grabButtonClasses = buildClassesWithDefault(
        'bg-gray-300 w-6 content-center rounded-l-lg',
        isDragging ? 'cursor-grabbing' : 'cursor-grab'
    )

    if (grabbingCard) {
        return (
            <div className='flex w-[100%]'>
                <Tooltip content='Přesunout' disabled={isDragging}>
                    {(propsButton, disabledButton) => {
                        return (
                            <button
                                className={grabButtonClasses}
                                {...(disabledButton ? {} : propsButton)}
                                {...(draggableHandleButtonProps as ButtonProps)}
                            >
                                <RxDragHandleDots2 size={25} className='h-full' />
                            </button>
                        )
                    }}
                </Tooltip>
                <div
                    {...props}
                    ref={ref}
                    className={
                        isSubcard ? 'bg-gray-100 border border-gray-300 border-l-0 w-[100%] rounded-r-lg' : classes
                    }
                >
                    {children}
                    {disabled && <div className='disabled-overlay' />}
                </div>
            </div>
        )
    }

    return (
        <div
            {...props}
            ref={ref}
            className={isSubcard ? 'bg-gray-100 rounded-[0.625rem] border border-gray-300' : classes}
        >
            {children}
            {disabled && <div className='disabled-overlay' />}
        </div>
    )
}

Card.displayName = 'Card'

export default forwardRef(Card)
