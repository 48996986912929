import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import Column from '../../base/grid/Column'
import PostInfoCardListItem from './PostInfoCardListItem'
import React from 'react'
import Row, { RowProps } from '../../base/grid/Row'
import Skeleton from 'react-loading-skeleton'
import useNewsList from '../../../hooks/api/news/useNewsList'

export type PostCardListProps = RowProps & {}

const PostCardList = ({ className, ...props }: PostCardListProps) => {
    const { data: posts, isLoading } = useNewsList()

    const renderSkeletonPosts = () => {
        return (
            isLoading && (
                <>
                    <Column cols={12}>
                        <Skeleton baseColor='white' width='45%' />
                        <Skeleton baseColor='white' height={200} />
                    </Column>
                    <Column cols={12}>
                        <Skeleton baseColor='white' width='45%' />
                        <Skeleton baseColor='white' height={200} />
                    </Column>
                    <Column cols={12}>
                        <Skeleton baseColor='white' width='45%' />
                        <Skeleton baseColor='white' height={200} />
                    </Column>
                </>
            )
        )
    }

    return (
        <Row {...props} className={buildClassesWithDefault('post-info-card-list', className)}>
            {posts?.map((item, index) => {
                return (
                    <Column cols={12} key={index}>
                        <PostInfoCardListItem title={item.title}>
                            <div
                                className='post-info-card-list-item-content'
                                dangerouslySetInnerHTML={{ __html: item.text }}
                            />
                        </PostInfoCardListItem>
                    </Column>
                )
            })}
            {renderSkeletonPosts()}
        </Row>
    )
}

export default PostCardList
