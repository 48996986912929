import {
    FloatingPortal,
    Placement,
    autoUpdate,
    safePolygon,
    shift,
    size,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole
} from '@floating-ui/react'
import { Transition } from '@headlessui/react'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { HTMLAttributes, ReactNode, useState } from 'react'

export type DropdownProps = HTMLAttributes<HTMLDivElement> & {
    activator: (props: Record<string, unknown>, ref: (node: unknown | null) => void) => ReactNode
    bordered?: boolean
    strategy?: 'absolute' | 'fixed'
    placement?: Placement
    zIndex?: number
}

const Dropdown = ({
    children,
    activator,
    bordered,
    className,
    strategy: strategyProp = 'fixed',
    placement = 'bottom',
    zIndex = 15,
    ...props
}: DropdownProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const { x, y, strategy, refs, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement,
        strategy: strategyProp,
        whileElementsMounted: autoUpdate,
        middleware: [
            size({
                apply({ elements }) {
                    Object.assign(elements.floating.style, {
                        minWidth: `${elements.reference.getBoundingClientRect().width}px`,
                        width: 'max-content'
                    })
                }
            }),
            shift()
        ]
    })

    const hover = useHover(context, {
        handleClose: safePolygon()
    })

    const focus = useFocus(context)
    const dismiss = useDismiss(context)
    const role = useRole(context, { role: 'menu' })

    const { getReferenceProps, getFloatingProps } = useInteractions([hover, dismiss, focus, role])

    return (
        <>
            {activator(getReferenceProps(), refs.setReference)}
            <FloatingPortal>
                <Transition show={isOpen}>
                    <Transition.Child
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                        aria-orientation='vertical'
                        aria-labelledby='menu-button'
                        ref={refs.setFloating}
                        style={{
                            position: strategy,
                            top: y ?? 0,
                            left: x ?? 0,
                            zIndex
                        }}
                        {...getFloatingProps(props)}
                        className={buildClassesWithDefault(
                            {
                                'dropdown-menu': true,
                                bordered
                            },
                            className
                        )}
                    >
                        <div className='py-1'>
                            <ul className='dropdown-menu-container' role='none'>
                                {children}
                            </ul>
                        </div>
                    </Transition.Child>
                </Transition>
            </FloatingPortal>
        </>
    )
}

export default Dropdown
