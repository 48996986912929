import { PropsWithChildren, createContext, useState } from 'react'

export type SideBarButtonContextProps = {
    isSidebarButtonVisible: boolean
    toggleButton: () => void
}

export const SideBarButtonContext = createContext<SideBarButtonContextProps>({} as SideBarButtonContextProps)

export const SideBarButtonProvider = ({ children }: PropsWithChildren) => {
    const [isSidebarButtonVisible, setIsSidebarButtonVisible] = useState(false)

    const toggleButton = () => {
        setIsSidebarButtonVisible(prevState => !prevState)
    }

    return (
        <SideBarButtonContext.Provider value={{ isSidebarButtonVisible, toggleButton }}>
            {children}
        </SideBarButtonContext.Provider>
    )
}
