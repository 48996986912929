import { ChevronRightIcon } from '@heroicons/react/outline'
import React from 'react'
import useTheme from '../../../hooks/useTheme'

const AppBarOpenMobileSidebarButton = () => {
    const { setOpenedSidebar } = useTheme()

    const openMobileSidebar = () => {
        return setOpenedSidebar(prevState => {
            return !prevState
        })
    }

    return (
        <div className='bg-gray-100 p-3 rounded-r-lg shadow-[0_0_8px_0] shadow-shadow z-10 fixed'>
            <button className='border border-primary rounded-md  h-8 px-1 ' onClick={openMobileSidebar}>
                <ChevronRightIcon className='h-5 w-5 text-primary' aria-hidden='true' />
            </button>
        </div>
    )
}

export default AppBarOpenMobileSidebarButton
