import classNames from 'classnames'
import color from 'color'

export const buildClasses = classNames
export const buildClassesWithDefault = classNames

export const hexToRgb = (colorValue: string, alpha: number = 1) => {
    return color(colorValue).alpha(alpha).string()
}

export const hexToRgbObject = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    const red = result ? parseInt(result[1], 16) : 0
    const green = result ? parseInt(result[2], 16) : 0
    const blue = result ? parseInt(result[3], 16) : 0
    return {
        red,
        green,
        blue
    }
}

export const generateShadesOfColor = (hex: string, variants = 10) => {
    const rgb = hexToRgbObject(hex)

    let red = rgb.red % 256
    let green = rgb.green % 256
    let blue = rgb.blue % 256

    const colors = []

    for (let i = 0; i < variants; i++) {
        colors.push(`rgb(${(red += 33)}, ${(green += 33)}, ${(blue += 33)})`)
    }

    return colors
}

export const pxToRem = (pixels: number | string) => {
    const pxToRemValue = 0.0625
    if (typeof pixels === 'string') {
        if (pixels.endsWith('rem')) {
            return pixels
        }
        return pxToRemValue * parseFloat(pixels)
    }
    return pxToRemValue * pixels
}
