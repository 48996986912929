import { APP_LOCALES } from '../../../config/config'
import { UpdateUserLocaleRequest } from '../../../services/api/user/UserService.types'
import { i18n } from 'next-i18next'
import { useCurrentUser } from '../../../auth/auth'
import { useUpdateUserLocale } from '../../../hooks/api/user/useUpdateUserLocale'
import Button from '../../base/button/Button'
import Dropdown from '../../base/dropdown/Dropdown'
import DropdownItem from '../../base/dropdown/DropdownItem'
import React from 'react'

export const AppBarLocalesDropdown = () => {
    const { user, refetchCurrentUser } = useCurrentUser()
    const { mutate: updateLocale } = useUpdateUserLocale()

    const onLocaleChange = (locale: string) => {
        const request: UpdateUserLocaleRequest = { defaultLocale: locale }
        updateLocale(request, {
            onSuccess: () => {
                i18n.changeLanguage(locale)
                refetchCurrentUser()
            }
        })
    }

    return (
        <div className='flex items-center -mr-4'>
            <Dropdown
                bordered
                activator={(props, ref) => (
                    <Button {...props} ref={ref} color='primary' variant='outlined'>
                        {user?.defaultLocale?.toUpperCase()}
                    </Button>
                )}
            >
                {APP_LOCALES.map((item, index) => (
                    <DropdownItem key={index} active={i18n.language === item} onClick={() => onLocaleChange(item)}>
                        {item.toUpperCase()}
                    </DropdownItem>
                ))}
            </Dropdown>
        </div>
    )
}
