import { SidebarProps } from './Sidebar'
import { buildClasses } from '../../../utils/StyleHelper'
import React, { ReactNode, useMemo } from 'react'
import SidebarListItem from './SidebarListItem'

export type SidebarListProps = Pick<SidebarProps, 'items'> & {
    mobile?: boolean
}

const SidebarList = ({ items, mobile = false }: SidebarListProps) => {
    const sidebarItems = useMemo<ReactNode>(() => {
        return items
            .filter(item => {
                return item.show === undefined || item.show
            })
            .map((item, index) => {
                return <SidebarListItem key={index} item={item} />
            })
    }, [items])

    return (
        <div className={buildClasses({ mobile: mobile, computer: !mobile })}>
            <nav className='flex flex-col h-full'>{sidebarItems}</nav>
        </div>
    )
}

export default SidebarList
