import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import PostCard from '../../base/post-card/PostCard'
import PostCardDescription from '../../base/post-card/PostCardDescription'
import PostCardTitle from '../../base/post-card/PostCardTitle'
import React, { HTMLAttributes, PropsWithChildren, ReactNode } from 'react'

export type PostCardListItemProps = HTMLAttributes<HTMLDivElement> & {
    title: string | ReactNode
}

const PostCardListItem = ({ className, children, title, ...props }: PropsWithChildren<PostCardListItemProps>) => {
    return (
        <PostCard {...props} className={buildClassesWithDefault('post-info-card-list-item', className)}>
            <PostCardTitle className='post-info-card-list-item-title'>{title}</PostCardTitle>
            <PostCardDescription className='post-info-card-list-item-description'>{children}</PostCardDescription>
        </PostCard>
    )
}

export default PostCardListItem
