import { IconProps } from './Icon.types'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React from 'react'

const DemandIcon = ({ fill, className }: IconProps) => {
    return (
        <svg
            className={buildClassesWithDefault('mr-3 h-[24px] w-[20px]', className)}
            width='24'
            height='28'
            viewBox='0 0 24 28'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M16.5215 12.3125C16.5215 11.7947 16.1018 11.375 15.584 11.375H5.9278C5.41001 11.375 4.9903 11.7947 4.9903 12.3125C4.9903 12.8303 5.41001 13.25 5.9278 13.25H15.584C16.1018 13.25 16.5215 12.8303 16.5215 12.3125Z'
                fill={fill}
            />
            <path
                d='M5.9278 15.125C5.41001 15.125 4.9903 15.5447 4.9903 16.0625C4.9903 16.5803 5.41001 17 5.9278 17H11.7924C12.3102 17 12.7299 16.5803 12.7299 16.0625C12.7299 15.5447 12.3102 15.125 11.7924 15.125H5.9278Z'
                fill={fill}
            />
            <path
                d='M8.13091 24.125H4.99548C3.96161 24.125 3.12048 23.2839 3.12048 22.25V5.75C3.12048 4.71613 3.96161 3.875 4.99548 3.875H16.5215C17.5554 3.875 18.3965 4.71613 18.3965 5.75V11.5156C18.3965 12.0334 18.8162 12.4531 19.334 12.4531C19.8518 12.4531 20.2715 12.0334 20.2715 11.5156V5.75C20.2715 3.68225 18.5893 2 16.5215 2H4.99548C2.92773 2 1.24548 3.68225 1.24548 5.75V22.25C1.24548 24.3177 2.92773 26 4.99548 26H8.13091C8.64869 26 9.06841 25.5803 9.06841 25.0625C9.06841 24.5447 8.64869 24.125 8.13091 24.125Z'
                fill={fill}
            />
            <path
                d='M21.9321 15.5735C20.8355 14.4769 19.0512 14.4769 17.9553 15.5728L12.8083 20.7084C12.699 20.8175 12.6184 20.9518 12.5735 21.0996L11.4525 24.7898C11.3532 25.117 11.4398 25.4723 11.6785 25.717C11.8574 25.9003 12.1004 25.9998 12.3496 25.9998C12.433 25.9998 12.5172 25.9887 12.5999 25.9658L16.3838 24.9176C16.5396 24.8745 16.6815 24.7918 16.7959 24.6776L21.9321 19.5511C23.0287 18.4545 23.0287 16.6702 21.9321 15.5735ZM15.6457 23.1765L13.7421 23.7038L14.2992 21.8696L17.7721 18.4044L19.0982 19.7305L15.6457 23.1765ZM20.607 18.2247L20.4253 18.406L19.0995 17.0801L19.2805 16.8994C19.646 16.5339 20.2408 16.5339 20.6063 16.8994C20.9718 17.265 20.9718 17.8597 20.607 18.2247Z'
                fill={fill}
            />
            <path
                d='M15.584 7.625H5.9278C5.41001 7.625 4.9903 8.04472 4.9903 8.5625C4.9903 9.08028 5.41001 9.5 5.9278 9.5H15.584C16.1018 9.5 16.5215 9.08028 16.5215 8.5625C16.5215 8.04472 16.1018 7.625 15.584 7.625Z'
                fill={fill}
            />
        </svg>
    )
}

export default DemandIcon
