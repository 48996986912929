import { useRouter } from 'next/router'

const useTypedRouter = <T = unknown>() => {
    const router = useRouter()
    return {
        ...router,
        query: router.query as T
    }
}

export default useTypedRouter
