import { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'
import { ThemeColor } from '../../../services/Service.types'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import { buttonConfig } from './Button.config'
import { removeUndefinedValuesFromObject } from '../../../utils/ObjectHelper'
import Loading from '../loading/Loading'
import React, { ElementType, ForwardedRef, MouseEvent, ReactNode, forwardRef } from 'react'

const defaultElementType = 'button'

export type ButtonVariant = 'outlined' | 'normal' | 'text'
export type ButtonSize = 'sm' | 'md' | 'lg'

type BaseButtonProps = {
    color?: ThemeColor
    variant?: ButtonVariant
    size?: ButtonSize
    leftIcon?: ReactNode
    rightIcon?: ReactNode
    icon?: boolean
    loading?: boolean
    stopPropagation?: boolean
}

export type ButtonProps<El extends ElementType = typeof defaultElementType> = PolymorphicPropsWithoutRef<
    BaseButtonProps,
    El
>

const Button = <El extends ElementType = typeof defaultElementType>(
    {
        as,
        children,
        variant = 'normal',
        className: classNameProp,
        color = 'primary',
        size = 'md',
        leftIcon,
        rightIcon,
        icon = false,
        loading = false,
        onClick,
        stopPropagation = true,
        ...props
    }: ButtonProps<El>,
    ref: ForwardedRef<HTMLElement>
) => {
    const Element: ElementType = as || defaultElementType
    const isDisabled = (props as unknown as Record<string, unknown>)?.disabled || loading
    const type =
        Element.toString() === 'button' ? (props as unknown as Record<string, unknown>)?.type || 'button' : undefined

    const className = buildClassesWithDefault(
        {
            button: true,
            'only-icon': icon,
            [`variant-${variant}`]: true,
            [buttonConfig.sizes.button[size]]: true,
            [buttonConfig.colors[isDisabled ? 'disabled' : color][variant]]: true
        },
        classNameProp
    )

    const iconClassName = buildClassesWithDefault(
        {
            [buttonConfig.sizes.icon[size]]: true
        },
        'icon'
    )

    const click = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        if (stopPropagation) {
            event.stopPropagation()
        }
        return (!loading || !isDisabled) && onClick && onClick(event)
    }

    const wrapIcon = (icon: ReactNode, additionalClassname?: string) => {
        if (!icon) {
            return
        }
        return <span className={buildClassesWithDefault(iconClassName, additionalClassname)}>{icon}</span>
    }

    const renderLeftIconOrLoading = () => {
        if (loading) {
            return wrapIcon(
                <Loading
                    className={buildClassesWithDefault(
                        buttonConfig.colors[color]['loadingIconColor'],
                        '!w-full !h-full'
                    )}
                    color='custom'
                />,
                icon ? '' : 'left'
            )
        }

        return wrapIcon(leftIcon, 'left')
    }

    const renderChildren = () => {
        if (icon && loading) {
            return
        }
        return icon ? wrapIcon(children) : children
    }

    return (
        <Element
            {...{ ...props, ...removeUndefinedValuesFromObject({ type }) }}
            ref={ref}
            onClick={click}
            className={className}
            disabled={isDisabled}
        >
            {renderLeftIconOrLoading()}
            {renderChildren()}
            {wrapIcon(rightIcon, 'right')}
        </Element>
    )
}

export default forwardRef(Button)
