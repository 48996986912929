import { ChevronLeftIcon } from '@heroicons/react/outline'
import Link from 'next/link'
import React from 'react'
import useTheme from '../../../hooks/useTheme'

const SidebarHeader = () => {
    const { getPageImage } = useTheme()
    const { setOpenedSidebar } = useTheme()

    const openMobileSidebar = () => {
        return setOpenedSidebar(prevState => {
            return !prevState
        })
    }

    return (
        <div className='flex flex-shrink-0  pt-4  bg-gray-100 '>
            <Link passHref href='/app'>
                <img className='w-48 px-6 py-6 -ml-2 ' src={`${getPageImage('logo.png')}`} alt='Workflow' />
            </Link>
            <div className='self-end pb-6 pt-5'>
                <button className='border border-primary rounded-md h-8 px-1' onClick={openMobileSidebar}>
                    <ChevronLeftIcon className='w-5 h-5 text-primary' aria-hidden='true' />
                </button>
            </div>
        </div>
    )
}

export default SidebarHeader
