import createAuthentication from '@dactyl-npm-packages/dactyl-auth-react'

export type User = {
    id: number
    oldLogin: string
    role: string
    rolePermissions: string[]
    firstName: string
    lastName: string
    email: string
    billingAddressCompanyName: string
    billingAddressCin: string
    defaultLocale: string
    logo: string
}

export type LoginRequestData = {
    username: string
    password: string
}

export type UpdateUserPasswordRequestData = {
    oldPassword: string
    newPassword: string
}

const { AuthContextProvider, useCurrentUser, useSignIn, useSignOut, useCurrentUserUpdatePassword, Authenticated } =
    createAuthentication<User>()

export { AuthContextProvider, useCurrentUser, useSignIn, useSignOut, useCurrentUserUpdatePassword, Authenticated }
