import { IconProps } from './Icon.types'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React from 'react'

export const ClockIcon = ({ className }: IconProps) => {
    return (
        <svg
            className={buildClassesWithDefault('mr-3 h-[24px] w-[20px]', className)}
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
        >
            <path strokeLinecap='round' strokeLinejoin='round' d='M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z' />
        </svg>
    )
}
