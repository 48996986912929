import { ThemeColor } from '../../../services/Service.types'
import { badgeConfig } from './Badge.config'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { ForwardedRef, HTMLAttributes, PropsWithChildren, ReactNode, forwardRef } from 'react'

export type BadgeVariant = 'normal' | 'outlined'
export type BadgeColor =
    | ThemeColor
    | 'blue'
    | 'red'
    | 'grey'
    | 'green'
    | 'orange'
    | 'light-green'
    | 'light-blue'
    | 'purple'

export type BadgeProps = Omit<HTMLAttributes<HTMLSpanElement>, 'color'> & {
    color?: BadgeColor
    variant?: BadgeVariant
    leftIcon?: ReactNode
    rightIcon?: ReactNode
}

const Badge = (
    {
        children,
        className,
        variant = 'normal',
        color = 'primary',
        leftIcon,
        rightIcon,
        ...props
    }: PropsWithChildren<BadgeProps>,
    ref: ForwardedRef<HTMLSpanElement>
) => {
    const classes = buildClassesWithDefault(
        {
            badge: true,
            [badgeConfig[color][variant]]: true
        },
        className
    )

    const wrapIcon = (icon: ReactNode, additionalClassname?: string) => {
        if (!icon) {
            return
        }
        return <span className={buildClassesWithDefault('icon', additionalClassname)}>{icon}</span>
    }

    return (
        <span {...props} className={classes} ref={ref}>
            {wrapIcon(leftIcon, 'left')}
            {children}
            {wrapIcon(rightIcon, 'right')}
        </span>
    )
}

export default forwardRef(Badge)
