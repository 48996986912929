import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { ForwardedRef, HTMLAttributes, PropsWithChildren, forwardRef } from 'react'

type Cols = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto'

export type ColumnProps = HTMLAttributes<HTMLDivElement> & {
    rounded?: boolean
    removePaddingX?: boolean
    removePaddingY?: boolean
    removeIfChildrenIsNull?: boolean
    cols?: Cols
    sm?: Cols
    md?: Cols
    lg?: Cols
    xl?: Cols
    xl2?: Cols
}

const Column = (
    {
        rounded,
        children,
        removePaddingX = false,
        removePaddingY = false,
        className,
        removeIfChildrenIsNull,
        cols = 'auto',
        sm,
        md,
        lg,
        xl,
        xl2,
        ...props
    }: PropsWithChildren<ColumnProps>,
    ref: ForwardedRef<HTMLDivElement>
) => {
    const getClasses = () => {
        const classes = {
            [`cols-${cols}`]: true,
            'px-1.5': !removePaddingX,
            'py-1.5': !removePaddingY,
            'rounded-2xl': rounded
        }
        if (sm) {
            classes[`cols-sm-${sm}`] = true
        }
        if (md) {
            classes[`cols-md-${md}`] = true
        }
        if (lg) {
            classes[`cols-lg-${lg}`] = true
        }
        if (xl) {
            classes[`cols-xl-${xl}`] = true
        }
        if (xl2) {
            classes[`cols-2xl-${xl2}`] = true
        }
        return buildClassesWithDefault(classes, className)
    }

    if (removeIfChildrenIsNull && !children) {
        return null
    }

    return (
        <div className={getClasses()} {...props} ref={ref}>
            {children}
        </div>
    )
}

export default forwardRef(Column)
