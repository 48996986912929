import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, ToastContainerProps } from 'react-toastify'
import React from 'react'

export type ToastProps = Omit<ToastContainerProps, 'toastClassName' | 'bodyClassName' | 'progressClassName'>

//Docs: https://fkhadra.github.io/react-toastify/introduction
const Toast = ({ position = 'top-right', ...props }: ToastProps) => {
    return (
        <ToastContainer
            {...props}
            className='toast-container'
            toastClassName='toast'
            bodyClassName='body'
            progressClassName='progress'
            position={position}
        />
    )
}

export default Toast
