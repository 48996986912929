import { AppBarLocalesDropdown } from './AppBarLocalesDropdown'
import { OrderStatus, PriceOffer } from '../../../services/api/order/OrderService.types'
import { environmentConfig } from '../../../config/environment/environmentConfig'
import { formatDate } from '../../../utils/DateHelper'
import AppBarTitle from './AppBarTitle'
import AppBarUserMenu from './AppBarUserMenu'
import React, { PropsWithChildren, ReactNode } from 'react'

export type AppBarProps = {
    title?: string | ReactNode
    showUserMenu?: boolean
    showDate?: boolean
    showButtonPriceOffer?: boolean
    demandStatus?: OrderStatus
    priceOffer?: PriceOffer[]
}

const AppBar = ({
    children,
    showUserMenu = true,
    showDate = true,
    title,
    demandStatus,
    priceOffer
}: PropsWithChildren<AppBarProps>) => {
    const date = formatDate(new Date(), String(environmentConfig.dateFormat))

    const renderDate = () => {
        if (showDate) {
            return <span className='text-body-small font-normal hidden lg:block color-[#1A202C]'>{date}</span>
        }
    }

    return (
        <div className='appbar'>
            <div className='wrapper'>
                <div className='flex-1 px-4 flex justify-between'>
                    <div className='flex items-center justify-between w-full ml-[30px]'>
                        <AppBarTitle title={title} demandStatus={demandStatus} priceOffer={priceOffer} />
                        <div className='flex items-center gap-9'>
                            {children}
                            {renderDate()}
                            <AppBarLocalesDropdown />
                            {showUserMenu && <AppBarUserMenu />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppBar
