import { AxiosError } from 'axios'
import { UpdateUserLocaleRequest } from '../../../services/api/user/UserService.types'
import { updateUserLocale } from '../../../services/api/user/UserService'
import { useMutation } from '@tanstack/react-query'
import useAxios from '../../useAxios'

export const useUpdateUserLocale = () => {
    const axiosInstance = useAxios()

    return useMutation<void, AxiosError, UpdateUserLocaleRequest>(async (request: UpdateUserLocaleRequest) => {
        return updateUserLocale(axiosInstance, request)
    })
}
