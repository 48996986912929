import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { MakeAxiosResponse } from '../../Service.types'
import { NewItem } from './NewsService.types'

export const getNewsList = async (
    axiosInstance: AxiosInstance,
    config: AxiosRequestConfig = {}
): Promise<NewItem[]> => {
    const {
        data: { data: newsList }
    } = await axiosInstance.get<MakeAxiosResponse<NewItem[]>>('news', config)
    return newsList
}
