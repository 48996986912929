import { Dispatch, PropsWithChildren, SetStateAction, createContext, useMemo, useState } from 'react'
import { Theme } from '../services/Service.types'

export type ThemeContextState = {
    theme?: Theme
    openedSidebar: boolean
    setOpenedSidebar: Dispatch<SetStateAction<boolean>>
    loading: boolean
    setLoading: (value: boolean) => void
}

export const themeContextDefaultState: ThemeContextState = {
    openedSidebar: false,
    loading: false,
    setOpenedSidebar: () => {},
    setLoading: () => {}
}

const ThemeContext = createContext<ThemeContextState>(themeContextDefaultState)

export type ThemeProviderProps = {
    theme: Theme
}

export const ThemeProvider = ({ children, theme }: PropsWithChildren<ThemeProviderProps>) => {
    const [openedSidebar, setOpenedSidebar] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)

    const themeContextState = useMemo<ThemeContextState>(() => {
        return {
            theme,
            setOpenedSidebar,
            openedSidebar,
            loading,
            setLoading
        }
    }, [loading, openedSidebar, theme])

    return <ThemeContext.Provider value={themeContextState}>{children}</ThemeContext.Provider>
}

export default ThemeContext
