import { ThemeProps } from '../theme/ThemeProps'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { HTMLAttributes } from 'react'

export type LoadingProps = HTMLAttributes<HTMLDivElement> & ThemeProps & {}

const Loading = ({ className, color = 'primary', ...props }: LoadingProps) => {
    return <div {...props} className={buildClassesWithDefault({ loading: true, [color]: true }, className)} />
}

export type WithLoadingProps = LoadingProps & {
    loading: boolean
}

export const withLoading = <P extends object>(Component: React.ComponentType<P>) => {
    const renderLoadingOrComponent = ({ loading, ...props }: WithLoadingProps) => {
        return loading ? (
            <div className='flex justify-center'>
                <Loading {...props} />
            </div>
        ) : (
            <Component {...(props as P)} />
        )
    }
    return renderLoadingOrComponent
}

export default Loading
