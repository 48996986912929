import { CSSProperties, useCallback, useContext, useMemo } from 'react'
import ThemeContext from '../contexts/ThemeContext'
import get from 'lodash/get'

const useTheme = () => {
    const { theme, openedSidebar, setOpenedSidebar, loading, setLoading } = useContext(ThemeContext)

    const getColor = useCallback(
        (color: Path<typeof theme.colors> | string): string => {
            return get(theme.colors as unknown as string, color)
        },
        [theme]
    )

    const getTextColorStyles = useCallback(
        (color: Path<typeof theme.colors>): CSSProperties => {
            return {
                color: getColor(color)
            }
        },
        [getColor, theme]
    )

    const getBackgroundColorStyles = useCallback(
        (color: Path<typeof theme.colors>): CSSProperties => {
            return {
                backgroundColor: getColor(color)
            }
        },
        [getColor, theme]
    )

    const getBorderColorStyles = useCallback(
        (color: Path<typeof theme.colors>): CSSProperties => {
            return {
                borderColor: getColor(color)
            }
        },
        [getColor, theme]
    )

    const getPublicFile = useCallback(
        (path: string) => {
            if (!theme) {
                return path
            }
            return `${theme.publicPath}/${path}`.trim()
        },
        [theme]
    )

    const getPageImage = (path: string) => {
        return `${getPublicFile('img')}/${path}`.trim()
    }

    const getPageFavicon = useCallback(
        (path: string) => {
            return `${getPublicFile('favicon')}/${path}`.trim()
        },
        [getPublicFile]
    )

    const dateFormat = useMemo<string>(() => {
        return theme?.dateFormat
    }, [theme?.dateFormat])
    const datetimeFormat = useMemo<string>(() => {
        return theme?.datetimeFormat
    }, [theme?.datetimeFormat])
    const dayOrder = useMemo<number[]>(() => {
        return theme?.dayOrder
    }, [theme?.dayOrder])

    const primaryColor = useMemo<string>(() => {
        return theme?.colors?.primary?.DEFAULT
    }, [theme?.colors?.primary?.DEFAULT])
    const secondaryColor = useMemo<string>(() => {
        return theme?.colors?.gray?.DEFAULT
    }, [theme?.colors?.gray?.DEFAULT])

    return {
        theme,
        getTextColorStyles,
        getBackgroundColorStyles,
        getBorderColorStyles,
        getPublicFile,
        getPageImage,
        getPageFavicon,
        getColor,
        dateFormat,
        datetimeFormat,
        dayOrder,
        openedSidebar,
        setOpenedSidebar,
        loading,
        setLoading,
        primaryColor,
        secondaryColor,
        iconBackgroundColor: theme?.colors.primary.DEFAULT || '',
        projectName: theme?.projectName || '',
        publicPath: theme?.publicPath || ''
    }
}

export default useTheme
