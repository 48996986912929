import { HTMLAttributes, PropsWithChildren } from 'react'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'

export type PostCardTitleProps = HTMLAttributes<HTMLHeadingElement> & {}

const PostCardTitle = ({ children, ...props }: PropsWithChildren<PostCardTitleProps>) => {
    return (
        <h2 className={buildClassesWithDefault(['post-card-title'])} {...props}>
            {children}
        </h2>
    )
}

PostCardTitle.displayName = 'PostCardTitle'

export default PostCardTitle
