import { getNewsList } from '../../../services/api/news/NewsService'
import { useCurrentUser } from '../../../auth/auth'
import { useQuery } from '@tanstack/react-query'
import useAxios from '../../useAxios'

const useNewsList = () => {
    const { isReady, isLogged } = useCurrentUser()
    const axiosInstance = useAxios()

    return useQuery(
        ['newsList'],
        async ({ signal }) => {
            return await getNewsList(axiosInstance, { signal })
        },
        {
            enabled: isReady && isLogged
        }
    )
}

export default useNewsList
