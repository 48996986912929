const I18NextHttpBackend = require('i18next-http-backend/cjs')

module.exports = {
    i18n: {
        reloadOnPrerender: process.env.NODE_ENV === 'development',
        defaultLocale: 'cs',
        locales: ['cs'],
        backend: {
            loadPath: 'http://stin.localhost/api/translation/simplified',
            customHeaders: {
                'Accept-Language': 'cs'
            }
        }
    },
    use: [I18NextHttpBackend],
    serializeConfig: false,
    ns: ['common']
}
