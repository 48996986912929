import { HTMLAttributes, PropsWithChildren } from 'react'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'

export type PostCardDescriptionProps = HTMLAttributes<HTMLParagraphElement> & {}

export const PostCardDescription = ({ children, ...props }: PropsWithChildren<PostCardDescriptionProps>) => {
    return (
        <div className={buildClassesWithDefault(['post-card-description'])} {...props}>
            {children}
        </div>
    )
}

PostCardDescription.displayName = 'PostCardDescription'

export default PostCardDescription
