import '../styles/globals.scss'
import 'tailwindcss/tailwind.css'
import { AuthContextProvider } from '../auth/auth'
import { AuthProviderProps } from '@dactyl-npm-packages/dactyl-auth-react/dist/src/auth/Auth.types'
import { AuthenticationSettings } from '@dactyl-npm-packages/dactyl-auth-react'
import { EnvironmentProvider } from '../contexts/environment/EnvironmentContext'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SideBarButtonProvider } from '../contexts/SideBarButtonContext'
import { ThemeProvider } from '../contexts/ThemeContext'
import { appWithTranslation } from 'next-i18next'
import { buildEnvironmentApiUrl } from '../utils/Helper'
import { environmentConfig } from '../config/environment/environmentConfig'
import AxiosContext from '../contexts/axios/AxiosContext'
import AxiosProvider from '../contexts/axios/AxiosProvider'
import ErrorBoundary from '../components/base/error-boundary'
import LoadingToast from '../components/dashboard/loading-toast/LoadingToast'
import NextI18nextConfig from '../next-i18next.config'
import NiceModal from '@ebay/nice-modal-react'
import React from 'react'
import Script from 'next/script'
import Toast from '../components/base/toast/Toast'
import type { AppProps } from 'next/app'

//#faf9f9
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
            refetchOnWindowFocus: false
        },
        mutations: {
            retry: 0
        }
    }
})

const AUTHENTICATION_CONFIG: Partial<AuthenticationSettings> = {
    logoutWhenFailedLoadUser: true,
    currentUserChangePasswordRoute: 'auth/change-password',
    currentUserRoute: 'user/detail-short',
    signInRoute: 'user/login'
}

const App = ({ Component, pageProps }: AppProps) => {
    return (
        <EnvironmentProvider config={pageProps?.environmentConfig || environmentConfig}>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={pageProps.theme}>
                    <NiceModal.Provider>
                        <SideBarButtonProvider>
                            <AxiosProvider baseUrl={pageProps?.apiUrl || buildEnvironmentApiUrl()}>
                                <AxiosContext.Consumer>
                                    {({ axios }) => {
                                        return (
                                            <AuthContextProvider
                                                axiosInstance={axios as unknown as AuthProviderProps['axiosInstance']}
                                                config={AUTHENTICATION_CONFIG}
                                            >
                                                <ErrorBoundary>
                                                    <Component {...pageProps} />
                                                </ErrorBoundary>
                                            </AuthContextProvider>
                                        )
                                    }}
                                </AxiosContext.Consumer>
                                <LoadingToast />
                            </AxiosProvider>
                        </SideBarButtonProvider>
                        <Toast position='top-right' />
                    </NiceModal.Provider>
                </ThemeProvider>
            </QueryClientProvider>
            <Script id='external_scripts' src='/external_scripts.js' />
        </EnvironmentProvider>
    )
}

export default appWithTranslation(App, NextI18nextConfig)
