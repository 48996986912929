import { ButtonSize, ButtonVariant } from './Button'
import { ThemeColor } from '../../../services/Service.types'

type ButtonConfig = {
    sizes: {
        button: Record<ButtonSize, string>
        icon: Record<ButtonSize, string>
    }
    colors: Record<ThemeColor | 'disabled', Record<ButtonVariant | 'loadingIconColor', string>>
}

export const buttonConfig: ButtonConfig = {
    sizes: {
        button: {
            sm: 'py-0.5 px-3 text-xs rounded-lg font-normal',
            md: 'py-[0.380rem] px-4 text-body-small rounded-lg font-normal',
            lg: 'font-normal'
        },
        icon: {
            sm: 'h-4 w-4',
            md: 'h-4 w-4',
            lg: 'h-7 w-7'
        }
    },
    colors: {
        primary: {
            outlined: 'bg-white text-primary border border-primary hover:text-primary-200 hover:border-primary-200',
            text: 'text-primary border border-transparent hover:text-primary-200',
            normal: 'bg-primary text-white border border-primary hover:bg-primary-200 hover:border-primary-200',
            loadingIconColor: 'text-primary'
        },
        secondary: {
            outlined:
                'bg-white text-secondary border border-secondary hover:text-secondary-200 hover:border-secondary-200',
            text: 'text-secondary border border-transparent hover:text-secondary-200',
            normal: 'bg-secondary text-white border border-secondary hover:bg-secondary-200 hover:border-secondary-200',
            loadingIconColor: 'text-secondary'
        },
        success: {
            outlined: 'bg-white text-success border border-success hover:text-success-300 hover:border-success-300',
            text: 'text-success border border-transparent',
            normal: 'bg-success text-white border border-success hover:bg-success-300 hover:border-success-300',
            loadingIconColor: 'text-success'
        },
        info: {
            outlined: 'bg-white text-info border border-info hover:text-info-300 hover:border-info-300',
            text: 'text-info border border-transparent hover:text-info-300',
            normal: 'bg-info text-white border border-info hover:bg-info-300 hover:border-info-300',
            loadingIconColor: 'text-info'
        },
        warning: {
            outlined: 'bg-white text-warning border border-warning hover:text-warning-300 hover:border-warning-300',
            text: 'text-warning border border-transparent hover:text-warning-300',
            normal: 'bg-warning text-white border border-warning hover:bg-warning-300 hover:border-warning-300',
            loadingIconColor: 'text-warning'
        },
        danger: {
            outlined: 'bg-white text-danger border border-danger hover:text-danger-300 hover:border-danger-300',
            text: 'text-danger border border-transparent hover:text-danger-300',
            normal: 'bg-danger text-white border border-danger hover:bg-danger-300 hover:border-danger-300',
            loadingIconColor: 'text-danger'
        },
        gray: {
            outlined: 'bg-white text-gray border border-gray hover:text-gray-500 hover:border-gray-500',
            text: 'text-gray border border-transparent hover:text-gray-500',
            normal: 'bg-gray text-white border border-gray hover:bg-gray-500 hover:border-gray-500',
            loadingIconColor: 'text-gray'
        },
        disabled: {
            outlined: 'bg-gray-100 text-gray-400 border border-gray-400',
            text: 'text-gray-400 border border-transparent',
            normal: 'bg-gray-200 text-gray-500 border border-gray-200',
            loadingIconColor: ''
        }
    }
}
